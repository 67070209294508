<!--
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-17 14:27:31
 * @LastEditTime: 2020-12-24 18:19:17
-->
<template>
  <div class="record">
    <div class="record_list"
         v-if="recordData || recordData.length > 0">
      <div class="list"
           v-for="(item, index) in recordData"
           :key="index"
           @click="goDetail(item)">
        <img class="img"
             :src="item.spzp"
             alt="" />
        <div class="right">
          <div class="title title1 van-multi-ellipsis--l1">
            {{ item.spmc }}
          </div>
          <div class="title title2">
            {{ item.spjs }}
          </div>
          <div class="title title3">入驻时间：{{ item.createTime }}
            <div class="tag"
                 :class="'tag'+item.status">{{item.status===2?'已拒绝':item.status===1?'已通过':'审核中'}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-btn white">
      <u-button type="primary"
                size="large"
                @click="addBusiness">添加商铺</u-button>
    </div>
  </div>
</template>

<script>
import { businessMyList } from "@utils/aRequest";
import UButton from '@/components/u-button';
export default {
  data () {
    return {
      recordData: [],
    };
  },
  computed: {
  },
  components: { UButton },
  created () {
    this.getInitialization();
  },
  methods: {
    getInitialization (userId) {
      var self = this;
      businessMyList({ pageNum: 0, pageSize: 100 }).then((data) => {
        if (data.code == "SUCCESS") {
          let da = data.data.content;
          da.forEach((item) => {
            if (item.spzp !== '[]') {
              item.spzp = item.spzp.replace(/\[|]/g, '').split(',').map(it => {
                return it.trim()
              })[0]
            } else {
              item.spzp = ''
            }
          });
          self.recordData = da;
        }
      });
    },
    goDetail (item) {
      let id = item.id
      if (item.status !== 2) {
        this.$router.push({ path: "/my/businessCheckIn", query: { id} });
      } else {
        this.$router.push({ path: "/my/shopsCheckIn", query: { id } });
      }
    },
    addBusiness () {
      this.$router.push({ path: "/my/shopsCheckIn" });
    },
  },
  mounted () {
  },
};
</script>

<style lang="less" scoped>
.record {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #f6f6f6;
  .record_list {
    padding-bottom: 5.706667rem /* 107/18.75 */;
    .list {
      padding: 1.28rem /* 24/18.75 */ 1.066667rem /* 20/18.75 */;
      width: 100%;
      background-color: #fff;
      margin-bottom: 6px;
      display: flex;
      justify-content: space-between;
      .img {
        width: 3.093333rem /* 58/18.75 */;
        height: 2.933333rem /* 55/18.75 */;
        border-radius: 0.266667rem /* 5/18.75 */;
      }
      .right {
        flex: 1;
        margin-left: 0.746667rem /* 14/18.75 */;
        .title {
          margin-bottom: 0.32rem /* 6/18.75 */;
          font-size: 0.906667rem /* 17/18.75 */;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .title1 {
          font-size: 0.906667rem /* 17/18.75 */;
          font-weight: 500;
          color: #333333;
        }
        .title2 {
          font-size: 0.8rem /* 15/18.75 */;
          color: #666666;
        }
        .title3 {
          font-size: 0.64rem /* 12/18.75 */;
          color: #b5b5b5;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .tag {
            width: 3.093333rem /* 58/18.75 */;
            height: 1.28rem /* 24/18.75 */;
            line-height: 1.173333rem /* 22/18.75 */;
            background: #f6f6fa;
            border: 0.053333rem /* 1/18.75 */ solid;
            border-radius: 0.266667rem /* 5/18.75 */;
            text-align: center;
            font-size: 0.746667rem /* 14/18.75 */;
            font-family: PingFang SC;
            font-weight: bold;
            &.tag0 {
              color: rgba(255, 187, 99, 1);
              border-color: rgba(255, 187, 99, 0.5);
            }
            &.tag1 {
              color: rgba(89, 158, 255, 1);
              border-color: rgba(89, 158, 255, 0.5);
            }
            &.tag2 {
              color: rgba(255, 99, 99, 1);
              border-color: rgba(255, 99, 99, 0.5);
            }
          }
        }
      }
    }
  }
  .noList {
    width: 100%;
    height: 100%;
    .img1 {
      width: 12rem;
      margin-top: 5rem;
      margin-bottom: 1rem;
    }
    .p1 {
      font-size: 0.8rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
}
</style>